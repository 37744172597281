import React, { useEffect, useRef, useState } from "react"

import * as S from "TwitchTourStyle.jsx"

import { AiOutlineInfoCircle } from "react-icons/ai"

const RELEASE_VERSION = "2.2"

const PARENT_DOMAIN = window.location.hostname
const REQUEST_PROXY_URL = "https://twitchtours.s3.us-east-2.amazonaws.com/streams.json"

const POSSIBLE_BINGO_SQUARES = [
    "hot tub stream",
    "vtuber",
    "cardboard cutout",
    "sitting knees up",
    "speed run",
    "nintendo game",
    "raid",
    "5000+ viewers",
    "fmv green screen",
    "tts",
    "slots",
    "irl stream",
    "straight up copyright",
    "vr game",
    "twitch adblock insert",
    "pokemon cards",
    "live music",
    "real animals",
    "144p",
    "afk",
    "offline",
    "back-to-back mmo",
    "browsing the internet",
    "watching other streamers",
    "too many flying emotes",
    "mobile game",
    "australian",
    "American flag on the wall",
    "fox news",
    "automated graphs, charts, wall of text",
    "somehow not EN",
    "racing setup",
    "drunk",
    "cooking",
    "cat cam",
    "unnatural hair color",
    "audio mix outta whack",
    "actually MLG",
    "stretched / aspect ratio",
    "vaping",
    "art",
    "obnoxious soundboard",
    "way too much overlay",
    "merch hawking",
    "rage",
    "streamer bad at game",
    "banger alert\nbanger alert\nbanger alert",
    "that's a lotta APM",
    "input tracker",
    "stream category???",
]


const RenderStreamWithChat = () => (
    <iframe
        src="stream.html"
        frameborder="0"
        allowfullscreen="true"
        scrolling="no"
        height="378"
        width="620"
        parent=""
    >
    </iframe>
)

const RenderStream = ({
    channel="monstercat",
}) => (
    <iframe
        src={`https://player.twitch.tv/?channel=${channel}&parent=${PARENT_DOMAIN}&?muted=false&?autoplay=true`}
        height="638"
        width="100%"
        allowfullscreen="true"
        frameborder="0"
    >
    </iframe>
)


const checkIfBingo = (bingoGridCheckedState) => {
    // bingoGridCheckedState is an array of booleans
    // the order of the array is top to bottom, left to right

    // return true if the user has a bingo, which is if they have checked
    // all the squares in a row, column, or diagonal

    // divide the grid into rows and columns
    const grid = []
    for (let i = 0; i < 5; i++) {
        grid.push(bingoGridCheckedState.slice(i * 5, i * 5 + 5))
    }

    // set the middle square of the middle row to true
    grid[2][2] = true

    // check rows
    for (let i = 0; i < 5; i++) {
        if (grid[i].every(square => square)) {
            return true
        }
    }

    // check columns
    for (let i = 0; i < 5; i++) {
        if (grid.map(row => row[i]).every(square => square)) {
            return true
        }
    }

    // check diagonals
    if (grid[0][0] && grid[1][1] && grid[2][2] && grid[3][3] && grid[4][4]) {
        return true
    }
    if (grid[0][4] && grid[1][3] && grid[2][2] && grid[3][1] && grid[4][0]) {
        return true
    }

    return false

}

const getRandomTwitchChannel = async (minViewers=0, maxViewers=1000) => {
    const url = REQUEST_PROXY_URL
    const response = await fetch(
        url,
        // {
        //     mode: "cors",
        // }
    )

    return response
}

let TIMER_SECONDS = 30
const TIMER_TIME_MS = TIMER_SECONDS * 1000
const CircleProgressBar = ({targetTime, ...props}) => {
    const [currentTime, setCurrentTime] = useState(0)

    const progress = (1 - (targetTime - currentTime) / TIMER_TIME_MS) * 100


    const { radius, stroke } = props;
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - progress / 100 * circumference;

    const seconds = Math.floor(((targetTime - currentTime) / 1000))

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(Date.now())
        }, 10)

        return () => {
            clearInterval(interval)
        }
    }, [targetTime])


    return (
        <svg
            height={radius * 2}
            width={radius * 2}
        >
            <circle
                stroke="#ac75ffaa"
                fill="transparent"
                strokeWidth={ stroke }
                strokeDasharray={ circumference + ' ' + circumference }
                style={ { strokeDashoffset } }
                stroke-width={ stroke }
                r={ normalizedRadius }
                cx={ radius }
                cy={ radius }
            />
            <text
                textAnchor="center"
                x="32"
                y="45"
                stroke="#FFFFFF99"
                color="white"
                fontSize="15"
                style={{
                    whiteSpace: "pre",
                    userSelect: "none",
                }}
            >
                    {seconds >= 0
                        ? seconds.toString().padStart(2)
                        : null
                    }
            </text>
        </svg>
    )
}

const TwitchTour = () => {
    const [channel, setChannel] = useState("monstercat")
    const [targetTime, setTargetTime] = useState(0)
    const channelHistoryRef = useRef([])

    const upcomingChannels = useRef([])
    const lastRollTime = useRef(0)
    const lastChannelChange = useRef(0)

    // store bingo grid checked state
    const [bingoGrid, setBingoGrid] = useState([])

    // store bingo square values
    const [bingoSquares, setBingoSquares] = useState([])

    // disco style
    const [disco, setDisco] = useState(false)

    // on first render, create a bingo card with a random value on each square
    useEffect(() => {
        const newBingoSquares = []
        // choose 25 random bingo squares without replacement

        // copy the possible bingo squares
        const possibleBingoSquares = [...POSSIBLE_BINGO_SQUARES]
        // shuffle the possible bingo squares
        possibleBingoSquares.sort(() => Math.random() - 0.5)
        // choose the first 25 squares
        const bingoSquares = possibleBingoSquares.slice(0, 25)

        // assert there are no duplicates
        const uniqueBingoSquares = [...new Set(bingoSquares)]
        if (uniqueBingoSquares.length !== bingoSquares.length) {
            console.log("duplicate bingo squares")
        }

        // store the bingo squares
        setBingoSquares(bingoSquares)

        // also initialize the bingo grid to all false
        const newBingoGrid = []
        for (let i = 0; i < 25; i++) {
            newBingoGrid.push(false)
        }
        setBingoGrid(newBingoGrid)

    }, [])


    // set disco if the user has a bingo (
    useEffect(() => {
        if (checkIfBingo(bingoGrid)) {
            setDisco(true)
        } else {
            setDisco(false)
        }

    }, [bingoGrid])

    const fetchUpcomingChannels = async () => {
        const response = await getRandomTwitchChannel()
        const responseObj = await response.json()

        const rollTime = responseObj.roll_time

        if (rollTime > lastRollTime.current) {
            const outOfStreams = upcomingChannels.current.length === 0

            // determine how many streams to skip when adding them to the queue
            const streamsToAdd = [...responseObj.streams]
            const secondsSinceRoll = (Date.now() / 1000) - rollTime
            if (secondsSinceRoll > TIMER_SECONDS) {
                // if we've already passed the first interval, just throw away the first stream
                streamsToAdd.shift()
            }

            upcomingChannels.current = [
                ...upcomingChannels.current,
                ...(streamsToAdd.map(stream => stream.user_name))
            ]

            // if we had run out of channels, start playing the next one right away
            if (outOfStreams) {
                playNextStream()
            }

            lastRollTime.current = rollTime
        }

        setTimeout(fetchUpcomingChannels, TIMER_TIME_MS)
    }

    const playNextStream = async (timeToNext=TIMER_TIME_MS) => {

        const timeSinceChange = Date.now() - lastChannelChange.current
        if (timeSinceChange < TIMER_TIME_MS) {
            setTimeout(playNextStream, TIMER_TIME_MS - timeSinceChange)
            return
        }

        const channel = upcomingChannels.current.shift()

        if (channel) {
            lastChannelChange.current = Date.now()

            // if there are more than 20 channels in history, remove the oldest one
            if (channelHistoryRef.current.length > 20) {
                channelHistoryRef.current.shift()
            }
            channelHistoryRef.current.push(channel)

            setChannel(channel)
            setTargetTime(Date.now() + timeToNext)
            // schedule the next channel change
            setTimeout(playNextStream, timeToNext)
        }

        // else, wait for next successful channel fetch to schedule next channel change
    }

    useEffect(() => {
        fetchUpcomingChannels()
    }, [])

    const renderHistory = () => {
        // render the channel history in reverse order without modifying the original array
        const history = [...channelHistoryRef.current].reverse()
        return history.map((channel, index) => (
            <S.HistoryItem
                key={index}
                opacity={(10 - index) / 10}
            >
                {/* render red circle before first item */}
                {index === 0 && <S.LiveCircle />}
                {/* render link to twitch channel */}
                <a href={`https://twitch.tv/${channel}`} target="_blank">{channel}</a>
            </S.HistoryItem>
        ))
    }

    const renderBingoSquares = () => {
        // map 25 bingo squares
        return Array.from(Array(25).keys()).map((index) => (
            <S.BingoSquare
                key={index}
                onClick={() => {
                    // update bingo grid state
                    const newBingoGrid = [...bingoGrid]
                    newBingoGrid[index] = !newBingoGrid[index]
                    setBingoGrid(newBingoGrid)
                }}
                checked={
                    // check if square is checked, middle square is always checked
                    index === 12 || bingoGrid[index]
                }
                disco={disco}
            >
                {
                    // middle square renders kekw gif, other squares render bingo text
                    index === 12
                    ? <img
                        // constrain size of gif to fit square
                        style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "18px",
                        }}
                        src={"https://c.tenor.com/ASGuOCPGrKEAAAAd/kekw-kek.gif"}
                        alt="kekw"
                        />
                    : bingoSquares[index]
                }
            </S.BingoSquare>
        ))
    }


    return (
        <S.Container>
            <S.Version>
                {`v${RELEASE_VERSION}`}
            </S.Version>
            <S.Disclaimer>
                <span>
                    Not affiliated with <a href="https://www.twitch.tv/">twitch.tv</a> in any way
                </span>
                <span>
                    Contact: <span href="mailto:twitchtours@gmail.com">twitchtours@gmail.com</span>
                </span>
            </S.Disclaimer>
            <S.InfoContainer>
                <S.InfoText>
                    See a new live stream every {TIMER_SECONDS} seconds. Everyone sees the same stream at the same time!
                </S.InfoText>
                <S.InfoIcon>
                    <AiOutlineInfoCircle/>
                </S.InfoIcon>
            </S.InfoContainer>
            <S.HistoryContainer>
                {renderHistory()}
            </S.HistoryContainer>
            <S.TopBarContainer>
                <S.TopBar>
                    <S.TitleText>
                        Take a tour of Twitch
                    </S.TitleText>
                </S.TopBar>
                <CircleProgressBar
                    targetTime={targetTime}
                    radius={40}
                    stroke={10}
                />
            </S.TopBarContainer>
            <S.FrameContainer>
                <RenderStream channel={channel}/>
            </S.FrameContainer>
            <S.BingoCardContainer>
                <S.BingoGrid>
                    {
                        renderBingoSquares()
                    }
                </S.BingoGrid>
            </S.BingoCardContainer>
            <S.MobileBingoCardContainer>
                <S.MobileBingoSpan>
                    Bingo
                </S.MobileBingoSpan>
                <S.BingoGrid>
                    {
                        renderBingoSquares()
                    }
                </S.BingoGrid>
            </S.MobileBingoCardContainer>
        </S.Container>
    )
}

export default TwitchTour
