import React from "react"

import TwitchTour from "TwitchTour.jsx"


function App() {
    return (
        <TwitchTour />
    )

}

export default App
