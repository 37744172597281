import styled from "styled-components"

export const Version = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
    font-size: 10px;
    color: gray;

    user-select: none;
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: black;
    height: 100vh;

    position: relative;
    overflow: hidden;
`

export const Disclaimer = styled.div`
    color: white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 20px;
    font-size: 14px;
    font-family: monospace;
    user-select: none;
    font-weight: 600;

    text-align: center;

    display: flex;
    flex-direction: column;
    gap: 10px;

    a {
        background-color: #6441a5;
        border-radius: 5px;
        padding: 5px;
        color: white;


        :hover {
            // swap the font color and background color
            color: #6441a5;
            // also set color for clicked link

            background-color: white;
        }
    }

    // on mobile, we want to show the disclaimer at the bottom of the screen
    @media (max-width: 420px), (max-height: 420px) {
        position: absolute;
        bottom: 0;
        padding: 10px;
        font-size: 12px;
        font-weight: 400;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;

        a {
            padding: 2px;
        }
    }
`

export const TopBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: black;
    font-size: 20px;
    width: 20%;
    border-radius: 10px;

    // gradient background with Twitch colors
    background: linear-gradient( to right, #6441a5, #2a0845 );
    color: white;

    font-family: monospace;
    font-weight: 600;
    font-size: 30px;
    flex: 1;
    flex-basis: 20%;
    flex-grow: 0;
    user-select: none;
    text-align: center;
`

export const TopBarContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 30px;
    font-size: 20px;
    flex-direction: row-reverse;

    color: white;

    // hide when on mobile
    @media (max-width: 420px), (max-height: 420px) {
        // display: none;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        // background-color: lime;
        flex-direction: row;
        padding: 0;
        margin-top: -15px;

        ${TopBar} {
            padding: 2px;
            width: 100%;
            flex: 1;
            font-size: 20px;
            min-width: 150px;
        }
    }
`

export const TitleText = styled.span`
`

export const InfoText = styled.span`
    font-family: monospace;
    font-size: 22px;
    flex: 1;
    flex-basis: 100%;
    padding-left: 20px;
    padding-right: 20px;
    user-select: none;
`

export const InfoIcon = styled.div`
    flex: 1;
    padding-top: 4px;
`

export const InfoContainer = styled.div`
    position: absolute;
    top: 30px;
    transform: translate(calc(70px - 100%), 0);

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    max-width: 30vw;

    left: calc(80px - 50%);
    left: 0px;

    display: flex;
    flex-direction: row;
    border-radius: 10px;
    padding: 10px;

    background-color: #00000000;
    transition: 0.2s;
    color: #a45fff;

    :hover {
        background: linear-gradient( to left, #222, #00000000 );
        transform: translate(0, 0px);

        svg {
            color: #a45fff;
        }
    }

    svg {
        color: #ac75ffaa;
        font-size: 48px;
        margin-left: 10px;

        :hover {
            color: #a45fff;
        }
    }

    // hide when on mobile
    @media (max-width: 420px), (max-height: 420px) {
        display: none;
    }
`

export const FrameContainer = styled.div`
    display: flex;
    flex: 1;
    padding: 50px;
    iframe {
        width: 100%;
        height: 100%;
    }
`

export const HistoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 350px;
    height: 550px;

    right: 0;
    top: 30vh;

    filter: drop-shadow( 0 0 5px black ) blur( 5px );
    opacity: 0.9;
    transition: 0.2s;

    :hover {
        filter: drop-shadow( 0 0 5px black ) blur( 0px );
        opacity: 1;
        transition: 0.2s;
    }

    overflow: hidden;

    // hide when on mobile
    @media (max-width: 420px), (max-height: 420px) {
        display: none;
    }
`

export const HistoryItem = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 20px;

    // bright twitch color font
    color: #ac75ff;

    font-family: monospace;
    font-weight: 600;

    border-radius: 10px;
    margin-bottom: 10px;

    background: linear-gradient( to right, #222, #00000000 );

    # set opacity by prop
    opacity: ${props => props.opacity};
    opacity: ${({opacity}) => opacity};

    transition: 0.2s;

    a, a:hover, a:focus, a:active {
        text-decoration: none;
        color: inherit;
    }

    a:hover {
        text-decoration: underline;
    }

    :hover {
        opacity: 1;
    }
`

export const LiveCircle = styled.div`
    // plain red circle
    background-color: #ff0000bb;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 10px;
`

export const TimerContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`

export const BingoCardContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 50vh;
    height: 40vh;
    left: 50%;
    transform: translate(-50%, 90%);
    overflow: hidden;

    transition: 0.2s;

    font-size: max(max(0.7vw, 0.9vh), 12px);

    :hover {
        height: 50vh;
        width: 60vh;
        transform: translate(-50%, 0%);
    }

    padding: 10px;

    // blur the background
    background-color: #a0aa0a0a;
    backdrop-filter: blur(5px);
    opacity: 0.9;
    transition: 0.2s;
    border-radius: 10px;

    // twitch colored translucent border
    border: 3px solid #ac75ffaa;
    border-bottom: none;

    // hide when on mobile
    @media (max-width: 420px), (max-height: 420px) {
        display: none;
    }
`

export const MobileBingoCardContainer = styled.div`
    // only show on mobile
    @media (min-width: 420px) {
        display: none;
    }

    display: flex;
    flex-direction: row;
    // gap: 4px;

    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(90%, -50%);


    transition: 0.2s;

    :hover {
        backdrop-filter: blur(50px);
        transform: translate(0px, -50%);
    }
`

export const MobileBingoSpan = styled.span`
    max-width: 20px;
    writing-mode: vertical-rl;
    text-orientation: upright;
    color: white;
    font-family: monospace;
    font-size: 20px;

    align-self: center;
    background-color: #6441a5;
    border-radius: 8px;
    padding: 4px;
    margin-right: 15px;

`

export const BingoGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-gap: 10px;
    width: 100%;
    height: 100%;
    background-color: #lime;

    // make all cells square
    & > div {
        width: 100%;
        height: 100%;
    }
`

export const BingoSquare = styled.div`
    // style based on the checked prop
    // use twitch themed colors
    background-color: ${props => props.checked ? '#ac75ffaa' : '#a0aa0a0a'};

    // black text if checked, black if not
    color: ${props => props.checked ? 'black' : 'white'};

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 18px;
    transition: 0.2s;
    :hover {
        // darker twitch color for hover
        background-color: #7c2feff5;
    }

    user-select: none;

    // center text, make it a bit more readable
    font-family: monospace;
    // font-size: 30px;
    font-weight: 600;
    text-align: center;

    // make the square change colors rapidly when the "disco" prop is true
    animation: ${props => props.disco ? 'disco-animation' : 'none'};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes disco-animation {
        0% {
            // background color based on the checked prop
            background-color: #a0aa0a0a;
        }
        25% {
            background-color: #ac75ffaa;
        }
        50% {
            background-color: #a0aa0a0a;
        }
        75% {
            background-color: #ac75ffaa;
        }
        100% {
            background-color: #a0aa0a0a;
        }
    }

`
